<template>
  <section>
    <b-card>
      <template #header>
        <div class="d-flex align-items-center">
          <span class="text-primary">Cerca Auto </span>
          <b-breadcrumb class="ml-2">
            <b-breadcrumb-item>
              <feather-icon
                icon="HomeIcon"
                scale="1.25"
                shift-v="1.25"
              ></feather-icon>
            </b-breadcrumb-item>
            <b-breadcrumb-item active> Cerca auto </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
        </template>

        <b-tabs ref="search-tab" class="mt-1">
          <b-tab title="Cerca per targa">
            <div class="container py-5">
              <b-row>
              <b-col md="6">
              <div class="text-start">
                 <img
                    class="d-block mx-auto mx-md-0 my-4"
                     src="@/assets/images/auto-targa.svg"
                  />
                <p class="text-primary-light font-weight-bolder">
                  Filtra i veicoli inserendo il numero di targa
                </p>
                <small
                  >Se non dovessi avere accesso al numero di targa, prova un
                  altro filtro fra quelli disponibili per la tua ricerca.</small
                >
              </div>
              </b-col>

             
              <b-col md="6" class="d-flex flex-column align-self-end">
              <b-input-group class="mt-3">
                <b-form-input placeholder="Inserisci il numero di targa" v-model="plate_search"/>
                <b-input-group-append>
                  <b-button variant="primary" @click="searchVehicle(plate_search, 'targa')"> Cerca </b-button>
                </b-input-group-append>
              </b-input-group>
              </b-col>
              </b-row>
            </div>
          </b-tab>

          <b-tab title="Cerca per telaio">
            <div class="container py-5">
               <b-row>
              <b-col md="6">
              <div class="text-start">
                <img
                class="d-block mx-auto mx-md-0 my-4"
                src="@/assets/images/auto-telaio.svg"
              />

                <p class="text-primary-light font-weight-bolder">
                  Filtra i veicoli inserendo il numero di telaio
                </p>
                <small
                  >Se non dovessi avere accesso al numero di telaio, prova un
                  altro filtro fra quelli disponibili per la tua ricerca.</small
                >
              </div>
              </b-col>

              <b-col md="6" class="d-flex flex-column align-self-end">
              <b-input-group class="mt-3">
                <b-form-input placeholder="Inserisci il numero di telaio" v-model="chassis_search"/>
                <b-input-group-append>
                  <b-button variant="primary" @click="searchVehicle(chassis_search, 'telaio')"> Cerca </b-button>
                </b-input-group-append>
              </b-input-group>
              </b-col>
              </b-row>
            </div>
          </b-tab>

          <b-tab title="Cerca per ID">
            <div class="container py-5">
              <b-row>
              <b-col md="6">
              <div class="text-start">
                <img
                class="d-block mx-auto mx-md-0 my-4"
                src="@/assets/images/auto-id.svg"
              />
                <p class="text-primary-light font-weight-bolder">
                  Filtra i veicoli inserendo l'ID a loro associato
                </p>
                <small
                  >Se non dovessi avere accesso all'ID del veicolo, prova un
                  altro filtro fra quelli disponibili per la tua ricerca.</small
                >
              </div>
              </b-col>

              <b-col md="6" class="d-flex flex-column align-self-end">
              <b-input-group class="mt-3">
                <b-form-input
                  placeholder="Inserisci l'ID associato al veicolo"
                  v-model="id_search"
                />
                <b-input-group-append>
                  <b-button variant="primary" @click="searchVehicle(id_search, 'id_auto')"> Cerca </b-button>
                </b-input-group-append>
              </b-input-group>
              </b-col>
              </b-row>
            </div>
          </b-tab>

          <!-- <b-tab title="Cerca per Documenti mancanti">
            <div class="container py-5">
              <b-row>
                <b-col md="6">
                  <div class="text-start">
                    <img
                      class="d-block mx-auto mx-md-0 my-4"
                      src="@/assets/images/auto-documenti.svg"
                    />
                    <p class="text-primary-light font-weight-bolder">
                      Filtra i veicoli inserendo l'ID a loro associato
                    </p>
                    <small
                      >Se non dovessi avere accesso all'ID del veicolo, prova un
                      altro filtro fra quelli disponibili per la tua ricerca.</small
                    >
                  </div>

                </b-col>
              
              <b-col md="6" class="d-flex flex-column align-self-center">

              <b-row class="mt-3">

              <b-col md="6">

              <b-form-group label="Marca">
                <b-form-select>
                  <b-form-select-option value=""></b-form-select-option>
                </b-form-select>
              </b-form-group>

              </b-col>

              <b-col md="6">

              <b-form-group label="Modello">
                <b-form-select>
                  <b-form-select-option value=""></b-form-select-option>
                </b-form-select>
              </b-form-group>

              </b-col>

              <b-col md="6">

              <b-form-group label="Provenienza">
                <b-form-select>
                  <b-form-select-option value=""></b-form-select-option>
                </b-form-select>
              </b-form-group>

              </b-col>

              <b-col md="6">

              <b-form-group label="Documento mancante">
                <b-form-select>
                  <b-form-select-option value=""></b-form-select-option>
                </b-form-select>
              </b-form-group>

              </b-col>

              <b-col md="6">

              <b-button variant="primary-light">
                Aggiungi un nuovo documento
              </b-button>

              </b-col>

              </b-row>

              </b-col>

              </b-row>

              <b-card-footer>
              <div class="text-right">
                <b-button variant="primary" class="mt-2 w-25"> Cerca </b-button>
              </div>
              </b-card-footer>
            </div>
          </b-tab> -->
        </b-tabs>
    </b-card>

    <div>

    <div class="text-center text-primary" v-if="loading">
      <b-spinner>

      </b-spinner>
    </div>

    <b-card v-if="!loading && search_result">

      <h2 class="text-primary mb-2">Risultati ricerca: </h2>

        <b-table :fields="fields" :items="search_result" responsive show-empty>

          <template #empty>
            <h5 class="text-center text-primary-light my-2">Non sono state trovate auto corrispondenti alla tua ricerca.</h5>
          </template>

          <template #cell(anno)="data">
            {{data.item.immatricolazione_anno}}
          </template>

          <template #cell(km)="data">
            {{data.item.chilometraggio}}
          </template>

          <template #cell(opzioni)="data">
          
          <span class="mr-2 text-nowrap">
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
              boundary="viewport"
            >
              <template #button-content>
                <feather-icon
                  style="color: gray"
                  size="20"
                  icon="LayersIcon"
                ></feather-icon>
              </template>
              <b-dropdown-item @click="$refs.vehicle_photo_modal.open(data.item.id_auto)">Foto</b-dropdown-item>
              <b-dropdown-item @click="$refs.vehicle_photo_examination_modal.open(data.item.id_auto)">Foto Perizia</b-dropdown-item>
              <b-dropdown-item>Dettagli Auto</b-dropdown-item>
              <b-dropdown-item @click="$refs.vehicle_documents_modal.open(data)">Documenti Associati</b-dropdown-item>
            </b-dropdown>

            <b-dropdown
              class="mx-2"
              size="sm"
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
              boundary="viewport"
            >
              <template #button-content>
                <feather-icon
                  style="color: gray"
                  size="20"
                  icon="EditIcon"
                ></feather-icon>
              </template>
              <b-dropdown-item @click="$refs.vehicle_photo_edit_modal.open(data)">Modifica Foto</b-dropdown-item>
              <b-dropdown-item @click="$refs.vehicle_photo_examination_edit_modal.open(data)">Modifica Foto Perizia</b-dropdown-item>
              <b-dropdown-item>Modifica Dettagli Auto</b-dropdown-item>
              <b-dropdown-item @click="$refs.vehicle_documents_edit_modal.open(data)">Modifica Documenti Associati</b-dropdown-item>
              <b-dropdown-item @click="$refs.vehicle_costs_edit_modal.open(data)">Modifica Costi</b-dropdown-item>
              <b-dropdown-item>Modifica Optionals</b-dropdown-item>
              <b-dropdown-item>Modifica Prezzi</b-dropdown-item>
              <b-dropdown-item @click="$refs.vehicle_status_edit_modal.open(data)">Modifica Status</b-dropdown-item>
              <b-dropdown-item @click="$refs.vehicle_location_edit_modal.open(data.item.ubicazione)">Modifica Ubicazione</b-dropdown-item>
            </b-dropdown>

            <b-dropdown
              size="sm"
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
              boundary="viewport"
            >
              <template #button-content>
                <feather-icon
                  style="color: gray"
                  size="20"
                  icon="FileTextIcon"
                  data-boundary="viewport"
                ></feather-icon>
              </template>
              <b-dropdown-item>Stampa Scheda</b-dropdown-item>
              <b-dropdown-item @click="$refs.vehicle_reservation_list_modal.open(data.item.id_auto)">Elenco Prenotazioni</b-dropdown-item>
              <b-dropdown-item>Gestione Pratiche</b-dropdown-item>
              <b-dropdown-item>Gestione Finanziamenti</b-dropdown-item>
            </b-dropdown>
          </span>

          </template>

          

        </b-table>
    </b-card>

    </div>

  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BBreadcrumb,
  BBreadcrumbItem,
  BButton,
  BButtonGroup,
  BCard,
  BCardTitle,
  BCardFooter,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormSelectOption,
  BInputGroup,
  BInputGroupAppend,
  BTable,
  BTabs,
  BTab,
  BSpinner,
} from "bootstrap-vue";

import {Requests} from '@/api';

export default {
  components: {
    BRow,
    BCol,
    BBreadcrumb,
    BBreadcrumbItem,
    BButton,
    BButtonGroup,
    BCard,
    BCardTitle,
    BCardFooter,
    BDropdown,
    BDropdownItem,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormSelectOption,
    BInputGroup,
    BInputGroupAppend,
    BTable,
    BTabs,
    BTab,
    BSpinner,
  },

  data: () => ({

    fields: [
      {
        key: "marca",
        sortable: true,
      },
      {
        key: "modello",
        sortable: true,
      },
      {
        key: "versione",
        sortable: true,
      },
      {
        key: "anno",
        sortable: true,
      },
      {
        key: "km",
        sortable: true,
      },
      {
        key: "targa",
        sortable: true,
      },
       {
        key: "telaio",
        sortable: true,
      },
      {
        key: "colore",
        sortable: true,
      },
      {
        key: "prezzo_pubblico",
        sortable: true,
      },
      {
        key: "prezzo_rivenditore",
        sortable: true,
      },
      {
        key: "opzioni",
        sortable: false,
      },

    ],  
  

    plate_search: '',
    chassis_search: '',
    id_search: '',
    search_result: null,

    //Helpers

    loading: false,
  }),

  methods: {
    async searchVehicle(value, type) {
      this.search_result = null;
      this.loading = true;

      let request_data = new FormData();

      request_data.append('q', value);
      request_data.append('t', type);

      try {
        const response = await Requests.searchVehicle(request_data)
        this.search_result = response
      } catch(err) {
        console.debug(err);
      }

      this.loading = false;
    }
  },
};
</script>
